<template>
  <div class="headline">
    <h1>Ihr speziallist für:</h1>
    <h1 class="second">- Beratung</h1>
    <h1 class="third">- Lernhilfe</h1>
    <h1 class="fourth">- Webseiten</h1>
    <h1 class="fifth">- Web-apps</h1>
  </div>
</template>

<script>
export default {
  name: 'HeadLine',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headline{
  margin-left: 10%;
}
h1 {
  font-size: 3.5em;
  margin: 0;
  text-align: start;
  color: white;
}
h1.second{
  margin-left: 4%;
}
h1.third{
  margin-left: 8%;
}
h1.fourth{
  margin-left: 12%;
}
h1.fifth{
  margin-left: 16%;
}
</style>
