<template>
  <div id="app">
    <div id="inside">
      <img alt="Vue logo" src="./assets/image.jpg" id="image">
      <Headline msg="Hallo Formatiker Roman"/>
    </div>
    <div id="below">
      <div>Copyright 2024</div>
      <div>Impressum</div>
    </div>
  </div>
</template>

<script>
import Headline from './components/Headline.vue'

export default {
  name: 'App',
  components: {
    Headline
  }
}
</script>

<style>
body{
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
}
#inside {
  max-width: 1200px;
  width: 100%;
}
#image{
  background-color: black;
  background-size: contain;
  background-position: center;
  width: 100%;
}
#below{
  background-color: black;
  background-size: contain;
  background-position: center;
  width: 100%;
}
</style>
